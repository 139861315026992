/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/

export default [
{
  header: 'Content',
  resource: 'auth',
},
{
  title: 'Reward',
  route: 'reward',
  icon: 'GiftIcon',
  resource: 'auth',
},
{
  title: 'Card Category',
  route: 'card-category',
  icon: 'ArchiveIcon',
  resource: 'auth',
},
{
  title: 'Card',
  route: 'card',
  icon: 'ArchiveIcon',
  resource: 'auth',
},
{
  title: 'Booster',
  route: 'booster',
  icon: 'ChevronsUpIcon',
  resource: 'auth',
},
{
  title: 'Support Item',
  route: 'support-item',
  icon: 'BatteryChargingIcon',
  resource: 'auth',
},
{
  title: 'Blocker',
  route: 'blocker',
  icon: 'PackageIcon',
  resource: 'auth',
},
{
  title: 'Level',
  route: 'level',
  icon: 'LayersIcon',
  resource: 'auth',
},
{
  title: 'Level Chest',
  route: 'level-chest',
  icon: 'GiftIcon',
  resource: 'auth',
},
{
  title: 'Level Pass',
  route: 'level-pass',
  icon: 'GiftIcon',
  resource: 'auth',
},
{
  title: 'Treasure Garden',
  route: 'treasure-garden',
  icon: 'GiftIcon',
  resource: 'auth',
},
{
  header: 'Tile Rank',
  resource: 'auth',
},
{
  title: 'Season',
  route: 'tile-rank-season',
  icon: 'LayersIcon',
  resource: 'auth',
},
{
  title: 'Map Pool',
  route: 'map-pool',
  icon: 'LayersIcon',
  resource: 'auth',
},
{
  title: 'Reward',
  route: 'tile-rank-reward',
  icon: 'LayersIcon',
  resource: 'auth',
},
{
  title: 'Virtual user',
  route: 'virtual-user',
  icon: 'UsersIcon',
  resource: 'auth',
},
{
  header: 'IAP',
  resource: 'auth',
},
{
  title: 'Product',
  route: 'product',
  icon: 'LayersIcon',
  resource: 'auth',
},
{
  title: 'Iap Product',
  route: 'iap-product',
  icon: 'LayersIcon',
  resource: 'auth',
},
{
  header: "Offer",
  resource: 'auth',
},
{
  title: 'Special Offer',
  route: 'offer',
  icon: 'ShoppingBagIcon',
  resource: 'auth',
},
{
  title: 'Landing Page',
  route: 'landing-page',
  icon: 'LayersIcon',
  resource: 'auth',
},
{
  header: 'MANAGEMENT',
  resource: 'auth',
},
{
  title: 'Config',
  route: 'config',
  icon: 'SettingsIcon',
  resource: 'auth',
},
{
  title: 'Contry',
  route: 'country',
  icon: 'FlagIcon',
  resource: 'auth',
},
{
  title: 'Language',
  route: 'language',
  icon: 'FlagIcon',
  resource: 'auth',
},
{
  title: 'User',
  route: 'user',
  icon: 'UserIcon',
  resource: 'auth',
},
{
  title: 'Information',
  route: 'info-company',
  icon: 'InfoIcon',
  resource: 'auth',
},
//----------------------------------------
{
  header: 'NOTIFICATIONS',
  resource: 'auth',
},
{
  title: 'Notification',
  route: 'notification',
  icon: 'BellIcon',
  resource: 'auth',
},
{
  title: 'Mails management',
  route: 'mail',
  icon: 'MailIcon',
  resource: 'auth',
},
// TOOL
{
  header: 'TOOL',
  resource: 'auth',
},
// admin
{
  header: 'ADMIN',
  resource: 'auth',
},
{
  title: 'Admin User',
  route: 'admin-user',
  icon: 'UsersIcon',
  resource: 'admin',
},
]
